// isomorphic-fetch adds global fetch if it does not exist.
import 'isomorphic-fetch'
import cache from 'app/util/cache'
import * as cookieUtil from 'app/util/cookieUtil'
import {requireDefined} from 'app/util/typeUtil'

class ResponseError extends Error {

  constructor(message: string, public response: Response) {
    super(message)
    // prototype hackery because https://github.com/Microsoft/TypeScript/issues/13965
    // https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#extending-built-ins-like-error-array-and-map-may-no-longer-work
    Object.setPrototypeOf(this, ResponseError.prototype)
  }
}

declare const NOCK_ABSOLUTE_URL: string | undefined // NOCK_ABSOLUTE_URL is defined in package.json for jest runs

function getBaseUrl(): string {
  if (typeof NOCK_ABSOLUTE_URL === 'string') { // The tests with nock-based mocking need absolute urls.
    return NOCK_ABSOLUTE_URL
  } else if (inServer) {
    return global.threadLocal.serverUrlForNodeFetch
  } else {
    return ''
  }
}

function withSeviHeaders(url: String, shouldAttachCsrf: boolean, init: RequestInit): RequestInit {
  const attachCookie = inServer && global.threadLocal.clientCookie && url.startsWith(getBaseUrl())
  const attachCsrfToken = shouldAttachCsrf && !inServer && ['GET', 'HEAD', 'OPTIONS'].indexOf(init.method || '') === -1

  return {
    ...init,
    headers: {
      ...init.headers,
      ...(attachCookie ? {
        cookie: global.threadLocal.clientCookie!
      } : {}),
      ...(attachCsrfToken ? {
        'csrf-token': requireDefined(cookieUtil.getCookieValue('csrf-token'))
      } : {}),
      ...(inServer && global.threadLocal.applicationHeader ? {
        'X-Application': global.threadLocal.applicationHeader
      } : {})
    }
  }
}

const ensureAbsoluteUrlWhenNecessary = (url: string): string => {
  const baseUrl = getBaseUrl()

  if (baseUrl && url.indexOf('://') < 0) {
    return baseUrl + url
  } else {
    return url
  }
}

const doFetch = (url: string, opts: RequestInit) => {
  const absoluteUrl = ensureAbsoluteUrlWhenNecessary(url)
  return fetch(absoluteUrl, withSeviHeaders(absoluteUrl, false, opts))
}

const storeToCache = (key: string, value: Response) => {
  cache.put(key, value)
  return value
}

/**
 * @param response response to check
 * @throws ResponseError if response code indicates an error
 */
const checkStatus = (response: Response): Response => {
  if (response.status >= 200 && response.status < 300) {
    return response
  } else {
    throw new ResponseError(response.statusText, response)
  }
}

/**
 * @param url url to fetch
 * @param noCache if cache should not be used...
 * @param jsonParseResponse if true, the response will be parsed as JSON, else as plaintext
 * @throws ResponseError if response code indicates an error
 */
const get = (url: string, noCache: boolean = false, jsonParseResponse: boolean = true): Promise<any> => {
  if (!noCache) {
    let cachedResponse = cache.get(url)
    if (cachedResponse) {
      return new Promise(resolve => resolve(cachedResponse))
    }
  }

  let opts: RequestInit = {
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json'
    }
  }
  return doFetch(url, opts)
    .then(checkStatus)
    .then((response: Response) => jsonParseResponse ? response.json() : response.text())
    .then((response: Response) => storeToCache(url, response))
}

const deleteRequest = (url: string): Promise<any> => {
  const csrfToken = cookieUtil.getCookieValue('csrf-token')
  const opts: RequestInit = {
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      ...(csrfToken ? {'csrf-token': csrfToken} : {})
    },
    method: 'delete'
  }
  return doFetch(url, opts)
    .then(checkStatus)
    .then((response: Response) => response.json())
}

/**
 * @param url url to patch to
 * @param data data to be sent with the request. This will be JSON stringified.
 * @throws ResponseError if response code indicates an error
 */
const patch = (url: string, data?: any): Promise<Response> => {
  const csrfToken = cookieUtil.getCookieValue('csrf-token')
  const opts: RequestInit = {
    body: JSON.stringify(data),
    credentials: 'same-origin',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      ...(csrfToken ? {'csrf-token': csrfToken} : {})
    },
    method: 'PATCH' // Patch is case sensitive uppercase unlike other methods because reasons
  }
  return doFetch(url, opts)
    .then(checkStatus)
}

/**
 * @param url url to post to
 * @param data data to be sent with the request. This will be JSON stringified.
 * @throws ResponseError if response code indicates an error
 */
const post = (url: string, data?: any): Promise<Response> => {
  const csrfToken = cookieUtil.getCookieValue('csrf-token')
  const opts: RequestInit = {
    body: JSON.stringify(data),
    credentials: 'same-origin',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      ...(csrfToken ? {'csrf-token': csrfToken} : {})
    },
    method: 'post'
  }
  return doFetch(url, opts)
    .then(checkStatus)
    .then((response: Response) => response.json())
}

/**
 * @param url url to post to
 * @param data data to be sent with the request. This will be JSON stringified.
 * @throws ResponseError if response code indicates an error
 */
const postFileDownload = (url: string, data?: any): Promise<Response> => {
  const csrfToken = cookieUtil.getCookieValue('csrf-token')
  const opts: RequestInit = {
    body: JSON.stringify(data),
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      ...(csrfToken ? {'csrf-token': csrfToken} : {})
    },
    method: 'post'
  }
  return doFetch(url, opts)
    .then(checkStatus)
    .then((response: Response) => response)
}

export {
  deleteRequest,
  get,
  patch,
  post,
  postFileDownload,
  ResponseError,
  getBaseUrl,
  withSeviHeaders,
  ensureAbsoluteUrlWhenNecessary
}
