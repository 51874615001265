import * as i18next from 'i18next'
import {Language, languages} from 'app/types/language'
import {ServiceView} from 'app/types/ServiceView'

interface Translations {
  [key: string]: string
}

export type TranslateFn = (s: string, options?: i18next.TOptions) => string

const convertLocalizationsToI18next = (translations: Translations) => {
  let converted: { [key: string]: Translations } = {
    citizen: {},
    common: {},
    company: {},
    instructions: {},
    official: {},
    frontpage: {},
    setup: {},
    messages: {},
    none: {}
  }

  for (const keyWithView of Object.keys(translations)) {
    const translation = translations[keyWithView]
    let [key, serviceView] = keyWithView.split('.')
    serviceView = serviceView || 'common'
    converted[serviceView][key] = translation
  }

  return converted
}

interface I18NextLike {
  t(key: string, options?: i18next.TOptions): string
  exists(key: string, options?: i18next.TOptions): boolean
  addResourceBundle(lang: string, namespace: string, bundle: any): void
  changeLanguage(lang: string): void
}

export class Translator {

  public static dummy(): Translator {
    return new Translator({
      t(key: string, options?: i18next.TOptions): string {
        return key
      },
      exists(key: string): boolean {
        return true
      },
      addResourceBundle(lang: string, namespace: string, bundle: any): void {
        // nop
      },
      changeLanguage(lang: string): void {
        // nop
      }
    }, false)
  }

  constructor(private i18n: I18NextLike, private showTranslationKeys: boolean) {
  }

  /**
   * @param key
   * @param lang
   * @param serviceView
   * @param options Parameters for interpolation and/or other i18next options. See http://i18next.com/docs/options/
   */
  public translate(key: string, lang: Language, serviceView: ServiceView, options: i18next.TOptions = {}) {
    if (this.showTranslationKeys) {
      return key
    } else {
      return this.i18n.t(key, {lng: lang, ns: serviceView, ...options})
    }
  }

  public hasTranslation(key: string, lang: Language, serviceView: ServiceView) {
    return this.i18n.exists(key, {lng: lang, ns: serviceView})
  }

  public addTranslations(lang: Language, translations: Translations) {
    const converted = convertLocalizationsToI18next(translations)
    for (const namespace of Object.keys(converted)) {
      const bundle = converted[namespace]
      this.i18n.addResourceBundle(lang, namespace, bundle)
    }
  }

  public setI18nextCookie(lang: Language) {
    this.i18n.changeLanguage(lang)
  }

  public getShowTranslationKeys() {
    return this.showTranslationKeys
  }
}

const loggingMissingKeyHandler = () => {
  let alreadyLoggedKeys: { [lngKey: string]: boolean; } = {}

  return (lngs: readonly string[], ns: string, key: string) => {
    lngs.forEach((lng) => {
      let lngKey: string = `${lng}:${key}`
      if (!alreadyLoggedKeys[lngKey]) {
        console.warn('Missing translation key for language ' + lng + ': ' + key)
        alreadyLoggedKeys[lngKey] = true
      }
    })
  }
}

const noOpMissingKeyHandler = (lngs: readonly string[], ns: string, key: string) => { /* no-op */
}

export function createI18nOptions(seviConfigOptions: any): i18next.InitOptions {
  return {
    compatibilityJSON: 'v3',
    detection: {caches: ['cookie']},
    debug: !inServer && seviConfigOptions.dev === true,
    defaultNS: 'common',
    fallbackLng: false,
    fallbackNS: 'common',
    interpolation: {
      escapeValue: false, // Prevent double escaping. React will do all necessary escaping.
      prefix: '%{',
      suffix: '}'
    },
    keySeparator: '<',
    missingKeyHandler: seviConfigOptions.suppressMissingTranslationLogging ? noOpMissingKeyHandler : loggingMissingKeyHandler(),
    ns: [
      'citizen',
      'company',
      'official',
      'instructions',
      'common',
      'frontpage',
      'setup',
      'messages',
      'none'
    ],
    nsSeparator: '>',
    resources: {}, // Suppresses warning about missing backend. Resources are loaded later with addResourceBundle.
    saveMissing: true,
    supportedLngs: [...languages] // defensive copy is needed because i18n modifies the array
  }
}
