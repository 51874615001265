const cacheInvalidationTime = 5 * 60 * 1000 // 5 minutes

function isValid(lastModified: number) {
  let time = Date.now() - lastModified
  return time <= cacheInvalidationTime
}

interface CacheEntry {
  lastModified: number
  value: Response
}

let store: {[key: string]: CacheEntry} = {}

export default class {

  public static put(key: string, value: Response) {
    if (!inServer) {
      store[key] = {
        lastModified: Date.now(),
        value: value
      }
    }
    return value
  }

  public static get(key: string): Response | null {
    return (!inServer && store[key] && isValid(store[key].lastModified)) ? store[key].value : null
  }

  public static clear() {
    store = {}
  }

}
