import {createI18nOptions, Translator} from 'app/i18n'
import i18next from 'i18next'
import i18nextLanguageDetector from 'i18next-browser-languagedetector'
import {get} from 'app/util/backend'
import {Language} from 'app/types/language'
import {getInitializationDataFromDocument} from 'app/initialize'

declare const OPTIONS: any

interface MyWindow extends Window {
  translator: Translator | undefined
}
declare const window: MyWindow

export async function initializeTranslator(): Promise<Translator> {
    if (window.translator == undefined) {
      await i18next.use(i18nextLanguageDetector)
        .init(createI18nOptions(OPTIONS)) // Result can be ignored as long as we are loading with the addTranslations below

      // window.location.search has leading '?' so it's stripped off first.
      const notranslation = !inServer && window.location.search.substring(1).split('&').indexOf('notranslation=true') >= 0

      window.translator = new Translator(i18next, notranslation)

      const lang: Language | null = getInitializationDataFromDocument('translations')

      if (lang != null) {
          return get('/api/translations/' + lang)
              .then((translations: { [key: string]: string }) => {
                  window.translator!.addTranslations(lang, translations)
                  return window.translator!
              })
      } else {
          return window.translator!
      }
    } else {
      return window.translator!
    }
}
