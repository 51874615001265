import 'app/webpackNonce'
import {initializeTranslator} from 'app/initializeTranslator'

Promise.all([
  devMode ? import(/* webpackChunkName: "appStyles" */ 'app/initializeAppStyles') : Promise.resolve(),
  initializeTranslator(),
  import('app/initializeApp').then(x => x.initializeApp),
]).then(([_, translator, initializeApp]) => {
  initializeApp(translator)
})
